import { Box, Button, Card, Flex, Group, Stack, Text } from '@mantine/core'
import { useTranslations } from 'next-intl'
import Head from 'next/head'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import React from 'react'
import TitleDivider from '../components/divider'
import Layout from '../components/layout'
import platformData from '../pages/data/top_country_hits.json'
import styles from '../styles/Home.module.css'
import { searchAPI_v2 } from '../utils/axios'

export default function Home({ title, platformHits }) {
  const { locale } = useRouter()
  const t = useTranslations('Index')
  const t_genres = useTranslations('Genres')

  const generateContentString = (contentType, genre, year) => {
    let contentString = contentType
    if (genre) contentString += ` | ${genre}`
    if (year) contentString += ` | ${year}`
    return contentString
  }

  return (
    <>
      <Head>
        <title>{title || t('pageTitle')}</title>
        <meta name="description" content={t('pageDescription')} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:title" content={title || t('pageTitle')} />
        <meta property="og:description" content={t('pageDescription')} />
        <meta
          property="og:url"
          content={`https://www.streamdiscover.com/${locale}`}
        />
        <meta property="og:image" content="/path/to/your/image.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title || t('pageTitle')} />
        <meta name="twitter:description" content={t('pageDescription')} />
        <meta name="twitter:image" content="/path/to/your/image.jpg" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <main
        className={styles.main}
        style={{
          width: '80vw',
          maxWidth: '1366px',
          margin: '0 auto'
        }}
      >
        <Stack
          w="100%"
          spacing={0}
          align="start"
          mt={60}
          sx={(theme) => ({
            width: '80vw',
            maxWidth: '100%',
            [theme.fn.smallerThan('md')]: { width: '85vw' },
            [theme.fn.smallerThan('sm')]: { width: '90vw' }
          })}
        >
          <TitleDivider titleText={t('trendingCarouselTitle')} />

          <Group
            position="apart"
            grow
            w="100%"
            my={24}
            spacing={48}
            sx={(theme) => ({
              [theme.fn.smallerThan('sm')]: {
                flexDirection: 'column',
                alignItems: 'center'
              }
            })}
          >
            {Object.keys(platformHits).map((key) => (
              <Box
                key={key}
                span={4}
                sm={6}
                md={4}
                lg={4}
                xl={4}
                sx={{
                  width: '100%',
                  marginBottom: '1rem'
                }}
              >
                <Card
                  sx={{
                    overflow: 'visible',
                    maxWidth: '100%'
                  }}
                  p={0}
                >
                  <Card.Section
                    sx={{
                      width: '100%',
                      margin: 0,
                      padding: 0,
                      height: '170px',
                      position: 'relative'
                    }}
                  >
                    <Image
                      src={`https://wsrv.nl/?url=${platformHits[key].data[0].backdrop}&w=600`}
                      fill
                      style={{
                        objectFit: 'cover',
                        borderRadius: '4px'
                      }}
                      alt="movie poster"
                    />
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        boxShadow: '0px -3px 0px 0px rgba(123,57,182,1) inset',
                        borderRadius: '4px'
                      }}
                    >
                      <Box
                        sx={{
                          position: 'absolute',
                          top: 0,
                          right: 0,
                          padding: '5px'
                        }}
                      >
                        <Text color="white" tt="uppercase" fw={700} size={12}>
                          {platformHits[key].data[0].title.length > 30
                            ? platformHits[key].data[0].title.substring(0, 30) +
                              '...'
                            : platformHits[key].data[0].title}
                        </Text>
                        <Text color="white" tt="uppercase" fw={500} size={10}>
                          {generateContentString(
                            t(platformHits[key].data[0].content_type),
                            t_genres(platformHits[key].data[0].genres?.[0]),
                            platformHits[key].data[0].year
                          )}
                        </Text>
                      </Box>
                      {platformHits[key].data[0].type === 'Series' && (
                        <Box
                          sx={{
                            position: 'absolute',
                            top: 0,
                            left: 10,
                            padding: '5px',
                            backgroundColor: 'rgba(123,57,182,1)',
                            borderRadius: '0 0 5px 5px',
                            display: 'flex',
                            placeContent: 'center'
                          }}
                          w={21}
                          h={19}
                        >
                          <Text color="white" size={10} fw={700} p={0}>
                            TV
                          </Text>
                        </Box>
                      )}
                      <Box
                        sx={{
                          position: 'absolute',
                          bottom: -30,
                          right: 0,
                          padding: '5px'
                        }}
                      >
                        <div
                          style={{
                            width: key === 'disney' ? 58 : 63,
                            height: key === 'disney' ? 58 : 63,
                            borderRadius: '8px',
                            overflow: 'hidden'
                          }}
                        >
                          <Image
                            src={platformHits[key].logo}
                            width={key === 'disney' ? 58 : 63}
                            height={key === 'disney' ? 58 : 63}
                            alt="logo"
                            style={{
                              objectFit: 'cover'
                            }}
                          />
                        </div>
                      </Box>
                    </Box>
                  </Card.Section>
                  <Stack
                    p="md"
                    sx={{
                      maxWidth: '100%',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden'
                    }}
                  >
                    {platformHits[key].data.slice(0, 5).map((hit, index) => {
                      const title = hit.translation?.title || hit.title
                      return (
                        <Link
                          href={`/${hit.content_type.toLowerCase()}/${
                            hit.slug || hit.uid
                          }`}
                          passHref
                          key={hit.uid}
                        >
                          <Flex align="center" sx={{ cursor: 'pointer' }}>
                            <Group
                              spacing={0}
                              sx={{ minWidth: '25%' }}
                              align="baseline"
                            >
                              <Text
                                fw={800}
                                color="rgba(123,57,182,1)"
                                size="35px"
                                sx={{
                                  lineHeight: 0.9,
                                  marginLeft: index === 0 ? '4px' : '0'
                                }}
                              >
                                {index + 1}
                              </Text>
                              {hit.poster ? (
                                <Image
                                  src={`https://wsrv.nl/?url=${hit.poster}&w=140`}
                                  width={45}
                                  height={67}
                                  alt="poster"
                                  style={{ borderRadius: '4px' }}
                                />
                              ) : (
                                <Box
                                  sx={{
                                    width: '45px',
                                    height: '67px',
                                    borderRadius: '4px',
                                    backgroundColor: 'rgba(255,255,255,0.1)',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                  }}
                                >
                                  <Text color="white" size="xs">
                                    NO IMAGE
                                  </Text>
                                </Box>
                              )}
                            </Group>
                            <Stack spacing={0}>
                              <Text
                                color="white"
                                tt="uppercase"
                                fw={400}
                                size={14}
                                sx={{
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  width: '220px'
                                }}
                              >
                                {title}
                              </Text>
                              <Text
                                color="#7B39B6"
                                tt="uppercase"
                                fw="bolder"
                                size={10}
                              >
                                {generateContentString(
                                  t(hit.content_type),
                                  t_genres(hit.genres?.[0]),
                                  hit.year
                                )}
                              </Text>
                            </Stack>
                          </Flex>
                        </Link>
                      )
                    })}
                  </Stack>
                </Card>
              </Box>
            ))}
          </Group>

          <Button
            sx={(theme) => ({
              alignSelf: 'center',
              fontSize: '10px',
              mb: 30,
              backgroundColor: '#7B39B6',
              color: 'inherit',
              [theme.fn.smallerThan('sm')]: {
                fontSize: '8px',
                width: '90%'
              }
            })}
            component={Link}
            href="/contents"
            style={{ textDecoration: 'none' }}
          >
            {t('discoverMoreButton')}
          </Button>
        </Stack>
      </main>
    </>
  )
}

Home.propTypes = {
  title: PropTypes.string,
  platformHits: PropTypes.object
}

Home.getLayout = function getLayout(page) {
  return (
    <Layout showSearchBar withAutocomplete showHero={true} pageName="Index">
      {page}
    </Layout>
  )
}

export async function getStaticProps({ locale }) {
  const countryCode = locale.split('-')[1]?.toLowerCase()
  const language = locale.split('-')[0]?.toLowerCase()

  const fetchPlatformHits = async (params) => {
    const { data: moviesData } = await searchAPI_v2.get('content/hits', {
      params: { ...params, type: 'movie' }
    })
    const { data: seriesData } = await searchAPI_v2.get('content/hits', {
      params: { ...params, type: 'series' }
    })
    return [...moviesData.data, ...seriesData.data].sort((a, b) => b.hits - a.hits)
  }

  const countryPlatforms = platformData[countryCode?.toLowerCase()] || []

  const platformParams = countryPlatforms.map((platform) => ({
    country: countryCode,
    type: ['movie', 'series'],
    limit: 10,
    platform: platform.id,
    logo: platform.logo,
    language: language
  }))

  try {
    /*     const { data: topMoviesData } = await searchAPI_v2.get('/content/hits', {
      params: moviesParams
    }) */
    const results = await Promise.all(
      platformParams.map((params) => fetchPlatformHits(params))
    )

    const hits = countryPlatforms.reduce((acc, platform, index) => {
      acc[platform.platform] = {
        data: results?.[index] || [],
        logo: platformParams[index].logo
      }
      return acc
    }, {})

    return {
      props: {
        platformHits: hits,
        countryPlatforms,
        messages: (await import(`../locales/${locale.split('-')[0]}/common.json`))
          .default
      },
      revalidate: 60 * 60 * 24 * 10
    }
  } catch (error) {
    console.error('Error al cargar los datos:', error)
    return {
      props: {
        error: 'No se pudieron cargar los datos',
        countryPlatforms,
        title: 'Stream Discover'
      },
      revalidate: 60 * 60 * 24 * 10
    }
  }
}
